<template>
  <section class="m-service-terms m-scroll">

    <!-- 头部导航 -->
    <m-header title="Customer Service" ></m-header>
      <div class="main">
      <router-link to  class="service-cell van-hairline--bottom">
        <div class="label">
          <span>Manage your orders</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to  class="service-cell van-hairline--bottom">
        <div class="label">
          <span>Returns & Refunds</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to  class="service-cell van-hairline--bottom">
        <div class="label">
          <span>Login & Security</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>

      <router-link to  class="service-cell van-hairline--bottom">
        <div class="label">
          <span>Cancel account</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>
    </div>

    <div class="main">
      <router-link to  class="service-cell van-hairline--bottom">
        <div class="label">
          <span>Contact Us</span>
        </div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="icon">
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

export default {
  name:'Security',
  components:{ MHeader },
  data(){
    return {
      account:{
        phone:'18068411355',
        // email:'416994573@qq.com',
        email:'',
        wechat:'Ace_柴',
        fb:'',
        ins:'',
      }
    }
  },
  methods:{

    // 退出当前账号
    handleSignOut(){
      this.$router.push({path:'/en/login'})
    },

    // 点击邮箱 
    handleEmail(){
      if(this.account.email){
        this.$router.push({path:'/en/service/checkemail'})
      }else{
        this.$router.push({path:'/en/service/bindemail'})
      }
    }
   
  },
}
</script>

<style lang="less" scoped>
.m-service-terms {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow: hidden;
  .main {
    background-color: #fff;
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    margin:14px 16px;
    width:calc(100% - 32px);
    border-radius: 8px;
    overflow:hidden;
    .service-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      .label, .right {
        display: flex;
        align-items: center;
      }
      .label {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .right {
        display: flex;
        align-items: center;
        height: 50px;
        .back {
          width: 18px;
          height: 18px;
          margin-left: 4px;
        }
      }
    }
  }
}
</style>